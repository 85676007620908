import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const NowPage = () => {
  const socialImage = {
    src: "/social.png",
    height: 2304,
    width: 4096
  }
  return (
    <Layout>
      <Seo 
        title="Now" 
        description="Where are we now?"
        image={socialImage}
      />
      <article className="flex flex-col max-w-screen-md mx-4 text-gray-700 lg:mx-auto">
        <h1 className="pb-1 mb-4 font-black text-8xl background-aussie-flag">Now!</h1>
        <span className="my-12 font-serif text-4xl font-bold text-center">"Time is the fire in which we burn."</span>
        <section className="grid max-w-screen-sm grid-cols-1 gap-4 mx-auto md:grid-cols-2">
          <h2 className="text-2xl">Location</h2> 
          <p className="text-2xl font-bold">Canberra, Australia</p>
          <h2 className="text-2xl">Professional title:</h2>
          <p className="text-2xl font-bold">The Collective</p>
          <h2 className="text-2xl">What do you do?</h2>
          <p className="text-2xl font-bold">Design and Engineer</p>
          <h2 className="text-2xl">Why?</h2>
          <p className="text-2xl font-bold">Some ideas are important enough to pursue!</p>
          <h2 className="text-2xl">What should we read?</h2>
          <ul className="text-2xl font-bold list-disc list-inside lg:list-outside">
            <li><a href="https://www.booktopia.com.au/the-master-and-his-emissary-iain-mcgilchrist/book/9780300245929.html">The Master and His Emissary</a></li>
            <li><a href="https://www.booktopia.com.au/mythos-stephen-fry/book/9781405934138.html">Mythos</a></li>
          </ul>
          <h2 className="text-2xl">URLs:</h2>
          <ul className="text-2xl font-bold list-disc list-inside lg:list-outside">
            <li><a href="https://www.anomaly.ltd">Website</a></li>
            <li><a href="https://github.com/anomaly/">Github</a></li>
            <li><a href="https://twitter.com/anomalyaus">Twitter</a></li>
          </ul>
        </section>

      </article>
    </Layout>
  )
}

export default NowPage
